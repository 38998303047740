import RepositoryFactory from "@/apis";
const registration = RepositoryFactory.registration;
const user = RepositoryFactory.user;

export default {
  createRegistration({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await registration.create(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  enquiryRegister({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await registration.enquiryRegister(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createBulkRegistration({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await registration.bulkCreate(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getData({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.getData(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  update({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await registration.update(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingle({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.getSingle(payload);
        // for (let i = 0; i < data.data.length; i++) {
        if (!data.data.amount) {
          if (data.data.category == "Member") {
            Object.assign(data.data, { amount: 12980 });
          }
          if (data.data.category == "Non-Member") {
            Object.assign(data.data, { amount: 14750 });
          }
          if (data.data.category == "Student") {
            Object.assign(data.data, { amount: 3540 });
          }
          if (data.data.category == "Academician") {
            Object.assign(data.data, { amount: 11210 });
          }
          if (data.data.category == "Early Bird") {
            Object.assign(data.data, { amount: 11800 });
          }
          if (
            data.data.category == "Non-Members : Delegate + Annual Membership"
          ) {
            Object.assign(data.data, { amount: 15340 });
          }
          if (
            data.data.category == "Non-Members : Delegate + Life Membership"
          ) {
            Object.assign(data.data, { amount: 21240 });
          }
          if (data.data.category == "Student : Delegate + Annual Membership") {
            Object.assign(data.data, { amount: 4130 });
          }
        }
        // }
        console.log("data", data);
        commit("SET_INVOICE_DETAILS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getRegisterPaymnetData({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await registration.getRegisterPaymnetData(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  verify({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.verify(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  bulkUpload({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.bulkUpload(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  downloadExcel({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.downloadExcel(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // for agenda Images

  getAllAgendaImages({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.getAllAgendaImages(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllPartnerImages({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.getAllPartnerImages(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getPartnerImagesforAdmin({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.getPartnerImagesforAdmin(payload);

        resolve(data.data[0]);
      } catch (error) {
        reject(error);
      }
    });
  },
  updatePartnerImages({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.updatePartnerImages(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createPartnerImages({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await registration.createPartnerImages(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
